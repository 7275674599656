<template>
  <div v-if="wallet" class="container">
    <h2>{{ $t('commons.wallet') }}</h2>
    <div class="wallet-iban">
      <div class="iban-infos-send">
        <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
        <p class="wallet-iban-desc">{{ $t('wallet.send_iban_payment') }}</p>
        <img @click="ibanInfos = false" v-if="ibanInfos" src="@/assets/svg/v2/arrowToBottomWhite.svg" alt="open iban" class="open-close-iban themed-img">
        <img @click="ibanInfos = true" v-else src="@/assets/svg/v2/arrowToRight.svg" alt="close iban" class="open-close-iban themed-img">
      </div>

      <div v-if="ibanInfos" class="iban-coords">
        <div>
          <h4>{{ $t('wallet.wallet_owner') }}</h4>
          <p>{{ wallet.iban.owner_name }}</p>
          <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('wallet.copy')"
                placement="top"
              >
              <img @click="copy(wallet.iban.owner_name)" src="@/assets/svg/v2/copy.svg" alt="copy owner name" class="themed-img">
            </el-tooltip>
        </div>
        <div>
          <h4>{{ $t('wallet.wallet_iban') }}</h4>
          <p>{{ transformIBAN(wallet.iban.iban) }}</p>
          <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('wallet.copy')"
                placement="top"
              >
              <img @click="copy(wallet.iban.iban)" src="@/assets/svg/v2/copy.svg" alt="copy owner name" class="themed-img">
            </el-tooltip>
        </div>
        <div>
          <h4>{{ $t('wallet.wallet_bic') }}</h4>
          <p>{{ wallet.iban.bic }}</p>
          <el-tooltip
                class="tooltip"
                effect="dark"
                :content="$t('wallet.copy')"
                placement="top"
              >
              <img @click="copy(wallet.iban.bic)" src="@/assets/svg/v2/copy.svg" alt="copy owner name" class="themed-img">
            </el-tooltip>
        </div>

        <p class="await-funds-desc">{{ $t('wallet.wallet_await_funds') }}</p>
      </div>
    </div>

    <div class="wallet-view">
      <div class="wallet-amounts">
        <div class="available-amounts">
          <p>{{ $t('wallet.available_amount') }}</p>
          <div>{{ wallet.available_amount + ' €' || '-' }}</div>
        </div>
        <div class="pending-amounts">
          <p>{{ $t('wallet.waiting_amount') }}</p>
          <div>{{ wallet.awaiting_amount + ' €' || '-' }}</div>
        </div>
        <div class="total-amount">
          <p>{{ $t('wallet.total_amount') }}</p>
          <div>{{ wallet.amount + ' €' || '-' }}</div>
        </div>
      </div>
      <button v-if="wallet.available_amount > 0" @click="modalConfirmPayout = true" class="wallet-view-btn action-light">{{ $t('wallet.withdraw_money') }}</button>
    </div>
    <table v-if="transactions.length > 0">
      <thead>
        <tr>
          <th></th>
          <th>
            <div class="th-content">
              Ticker
            </div>
          </th>
          <th>
            <div class="th-content">
              {{ $t('commons.name') }}
            </div>
          </th>
          <th>
            <div class="th-content">
              {{ $t('commons.date') }}
            </div>
          </th>
          <th>
            <div class="th-content">
              {{ $t('wallet.money_movement') }}
            </div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(transaction, i) in transactions" :key="i">
          <td class="state">
            <img
              v-if="transactionTypeLabel(transaction.type, transaction.indication) === 'Buy'"
              class="buy light-icon"
              src="@/assets/svg/v2/products/buyOrder.svg"
              alt="buy"
            />
            <img
              v-if="transactionTypeLabel(transaction.type, transaction.indication) === 'Sell'"
              class="sell light-icon"
              src="@/assets/svg/v2/products/sellOrder.svg"
              alt="sell"
            />
            <img
              v-else-if="transaction.type === 'TRANSFER' || transaction.type === 'BANK_WIRE'"
              class="refunds themed-img"
              src="@/assets/svg/v2/products/buySell.svg"
              alt="refunds"
            />
            <img
              v-else-if="transaction.type === 'PAYOUT'"
              class="payout themed-img"
              src="@/assets/svg/v2/settings/bank.svg"
              alt="payout"
            />

            {{ transactionTypeLabel(transaction.type, transaction.indication) }}

          </td>
          <td>
            <button v-if="transaction.type === 'PAYIN'" class="btn-ticker">{{ transaction.ticker }}</button>
            <span v-else>-</span>
          </td>

          <td v-if="transaction.type === 'PAYOUT' && transaction.indication ==='OUT'">{{ $t('wallet.bank_payout_in_infos') }}</td>
          <td v-else-if="transaction.type === 'BANK_WIRE' && transaction.indication ==='IN'">{{ $t('wallet.wallet_supply') }}</td>
          <td v-else-if="transaction.type === 'PAYIN'">{{ transaction.title }}</td>
          <td v-else>-</td>
          
          <td>{{ transaction.created_at }}</td>

          <td v-if="transaction.type === 'PAYIN' && transaction.indication === 'OUT'" class="executed">- {{ transaction.debited_funds }}</td>
          <td v-if="transaction.type === 'PAYIN' && transaction.indication === 'IN'" class="received">+ {{ transaction.credited_funds }}</td>
          <td v-if="transaction.type === 'BANK_WIRE' && transaction.indication === 'IN'" class="received">+ {{ transaction.credited_funds }}</td>
          <td v-if="transaction.type === 'TRANSFER' && transaction.indication ==='IN'" class="received">+ {{ transaction.debited_funds }}</td>
          <td v-if="transaction.type === 'TRANSFER' && transaction.indication ==='OUT' && transaction.debited_funds > 0 && transaction.fees > 0" class="executed">- {{ transaction.debited_funds }}</td>
          <td v-if="transaction.type === 'TRANSFER' && transaction.indication ==='OUT' && transaction.credited_funds > 0 && transaction.credited_funds !== transaction.debited_funds && transaction.fees < 0" class="received">+ {{ transaction.credited_funds }}</td>
          <td v-if="transaction.type === 'PAYOUT' && transaction.indication ==='OUT' && transaction.bank_target === 'OUT'" class="bank-payout">- {{ parseFloat(transaction.debited_funds - transaction.fees).toFixed(2) }}</td>
          <td v-if="transaction.type === 'PAYOUT' && transaction.indication ==='OUT' && transaction.bank_target === 'IN'" class="bank-payout">- {{ transaction.debited_funds }}</td>

          <td>
            <button v-if="transaction.type === 'PAYIN' && transaction.indication === 'OUT'" class="btn-status executed-btn">{{ paymentTypeLabel(transaction.type, transaction.indication) }}</button>
            <button v-if="transaction.type === 'PAYIN' && transaction.indication === 'IN'" class="btn-status received-btn">{{ paymentTypeLabel(transaction.type, transaction.indication) }}</button>
            <button v-if="transaction.type === 'TRANSFER' && transaction.indication ==='IN'" class="btn-status received-btn">{{ paymentTypeLabel(transaction.type, transaction.indication) }}</button>
            <button v-if="transaction.type === 'TRANSFER' && transaction.indication ==='OUT' && transaction.debited_funds > 0 && transaction.fees > 0" class="btn-status executed-btn">{{ paymentTypeLabel(transaction.type, transaction.indication) }}</button>
            <button v-if="transaction.type === 'TRANSFER' && transaction.indication ==='OUT' && transaction.credited_funds > 0 && transaction.credited_funds !== transaction.debited_funds && transaction.fees < 0" class="btn-status received-btn"> {{ $t('wallet.transfert_in') }}</button>
            <button v-if="transaction.type === 'BANK_WIRE' && transaction.indication ==='IN'" class="btn-status received-btn"> {{ $t('wallet.transfert_in') }}</button>
            <button v-if="transaction.type === 'PAYOUT' && transaction.indication ==='OUT' && transaction.bank_target === 'OUT'" class="btn-status bank-payout-btn">{{ paymentTypeLabel(transaction.type, transaction.indication, transaction.bank_target) }}</button>
            <button v-if="transaction.type === 'PAYOUT' && transaction.indication ==='OUT' && transaction.bank_target === 'IN'" class="btn-status bank-payout-btn">{{ paymentTypeLabel(transaction.type, transaction.indication, transaction.bank_target) }}</button>
          </td>
        </tr>
      </tbody>
    </table>

    <p v-else>{{ $t('wallet.empty') }}</p>

    <button v-if="transactions.length > 0" @click="$router.push('/auth/transactions/history')" class="transactions-history-wallet">{{ $t('wallet.transactions_history') }}</button>

    <el-drawer v-model="modalConfirmPayout" :title="$t('wallet.payout_modal_title')" :size="drawerSize" @close="closeModal">
      <confirmPayout :amount="wallet.available_amount" @confirmPayout="sendPayout" />
    </el-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import confirmPayout from '@/components/modules/dialogs/confirmPayout.vue'
import { ElMessage } from 'element-plus'

export default {
  components: {
    confirmPayout
  },
  data() {
    return {
      modalConfirmPayout: false,
      ibanInfos: false
    }
  },
  computed: {
    ...mapGetters({
      wallet: 'getWallet',
      transactions: 'getWalletTransactions'
    }),
    drawerSize() {
      if (window.innerWidth < 500) {
        return '100%';
      }
      return '40%';
    },
  },
  mounted() {
    this.myWallet();
  },
  methods: {
    ...mapActions({
      myWallet: 'myWallet',
      walletPayout: 'walletPayout'
    }),
    transactionTypeLabel(type, indication) {
      if (type === 'PAYIN' && indication === 'IN') {
        return 'Sell'
      } else if (type === 'PAYIN' && indication === 'OUT') {
        return 'Buy'
      } else if (type === 'TRANSFER') {
        return ''
      }
    },
    paymentTypeLabel(type, indication, bank) {
      if (type === 'PAYIN' && indication === 'OUT') {
        return this.$t('wallet.payin_success')
      } else if (type === 'PAYIN' && indication === 'IN') {
        return this.$t('wallet.payin_received')
      } else if (type === 'TRANSFER' && indication === 'IN') {
        return this.$t('wallet.transfert_in')
      } else if (type === 'TRANSFER' && indication === 'OUT') {
        return this.$t('wallet.transfert_out')
      } else if (type === 'PAYOUT' && indication === 'OUT' && bank === 'OUT') {
        return this.$t('wallet.bank_payout')
      } else if (type === 'PAYOUT' && indication === 'OUT' && bank === 'IN') {
        return this.$t('wallet.bank_payout')
      }
    },
    async sendPayout() {
      await this.walletPayout().then(() => {
        this.modalConfirmPayout = false;

        setTimeout(() => {
          window.location.reload();
        }, 700);
      })
    },
    closeModal() {
      this.modalConfirmPayout = false;
    },
    transformIBAN(iban) {
      return iban.replace(/(.{4})/g, '$1 ').trim();
    },
    copy(c) {
      navigator.clipboard.writeText(c);

      ElMessage({
        message: this.$t('wallet.copy_success'),
        type: 'success',
        grouping: true,
      })
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.container {
  margin: 30px 66px;
}

h2 {
  font-weight: 600;
}

.wallet-view,
.wallet-amounts {
  display: flex;
  flex-direction: row;
}

.wallet-view {
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
  align-items: center;
}

.wallet-amounts {
  max-width: 612px;
  width: 100%;
  padding: 15px;
  gap: 20px;
  border: solid 1px var(--border-grey);
  border-radius: 12px;
  background-color: var(--background-color-2);
  div {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    gap: 10px;
    p {
      font-size: 15px;
      color: var(--text-color);
    }
    div {
      font-size: 24px;
      font-weight: 500;
      color: var(--text-color);
    }
  }
}

.pending-amounts,
.total-amount {
  border-left: solid 1px var(--border);
  padding-left: 15px;
}

.wallet-view-btn {
  height: 54px;
  padding: 5px 10px;
  border-radius: 4px;
  border: none;
  background-color: $primary-color;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 500;
}

table {
  max-width: 99%;
  max-height: 463px;
  border-collapse: collapse;
  background: var(--background-color-2);
  border: solid 1px var(--border);
  border-radius: 12px;
  overflow: hidden;
}

th,
td {
  text-align: left;
  height: 45px;
  align-items: center;
}

th {
  font-weight: 500;
  color: var(--subtitle-2-text-color);
  border-bottom: solid 1px var(--border);
}

.th-content {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text-color);
}
.th-arrows {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  img {
    width: 10px;
    height: 8px;
  }
}

tbody tr:nth-child(even) {
  background-color: var(--background-color-3);
}

th:nth-child(2) {
  min-width: 100px;
}

td:nth-child(4) {
  min-width: 150px;
  // color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
  font-weight: 500;
}

th:nth-child(3) {
  min-width: 200px;
  width: 405px;
}

th:nth-child(6) {
  width: 200px;
}

.btn-ticker {
  border: 1px solid var(--bg-constrast);
  border-radius: 6px;
  margin-right: 50px;
  padding: 6px 4px;
  background-color: inherit;
  color: var(--text-color);
  min-width: 80px;
}
.state {
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  margin: 0 20px;

  img {
    width: 16px;
    height: 16px;
    border-radius: 25px;
    padding: 2px;
  }
  .buy {
    background: var(--buysuccess-color);
  }
  .sell {
    background: $sell-color;
  }
  .giver {
    background: $primary-color;
  }
}

.executed, .executed-btn {
  text-align: center;
  color: $executed-color;
  border-color: $executed-color;
}

td {
  .btn-status {
    margin-left: 20px;
    border: 1px solid;
    border-radius: 6px;
    padding: 6px 4px;
    width: 153px;
    background-color: inherit;
  }
}

.immobilized, .immobilized-btn {
  text-align: center;
  color: $warning-color;
  border-color: $warning-color;
}

.received, .received-btn {
  text-align: center;
  color: var(--buysuccess-color);
  border-color: var(--buysuccess-color);
}

.collateralized, .collateralized-btn {
  text-align: center;
  color: var(--light-blue);
  border-color: var(--light-blue);
}

.bank-payout, .bank-payout-btn {
  color: var(--text-color);
  border-color: var(--text-color);
}

.executed, .immobilized, .received, .collateralized, .bank-payout {
  text-align: right;
}

.transactions-history-wallet {
  margin-top: 15px;
  padding: 5px 8px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  border: none;
  background-color: $primary-color;
  margin-bottom: 8px;
  margin-right: 5px;
  color: $light-color;
}

.wallet-iban {

  .iban-infos-send {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .wallet-iban-desc {
      color: var(--buysuccess-color);
      margin-left: 5px;
    }

    .open-close-iban {
      margin-left: 5px;
      color: var(--text-color);
      cursor: pointer;
    }
  }


  .iban-coords {
    max-width: 612px;
    padding: 15px;
    gap: 20px;
    border: solid 1px var(--border-grey);
    border-radius: 12px;
    background-color: var(--background-color-2);
    margin-top: 5px;

    div {
      display: flex;

      p {
        margin-left: 5px;
      }

      img {
        cursor: pointer;
        margin-left: 8px;
      }
    }

    .await-funds-desc {
      margin-top: 8px;
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 1090px) {
  .container {
    margin: 30px 45px;
  }

  table thead, table tbody {
    overflow: scroll;
  }
}

@media screen and (max-width: 720px) {
  .container {
    margin: 30px 15px;
  }
}

@media screen and (max-width: 500px) {
  .wallet-view {
    gap: 5px;
  }

  .wallet-amounts {
    padding: 10px;
    gap: 0px;
  }

  .available-amounts, .pending-amounts, .total-amount {
    padding-left: 0px;

    div {
      font-size: 16px!important;
    }

    p {
      font-size: 13px;
    }
  }

  .available-amounts {
    padding-right: 10px;
  }

  .pending-amounts {
    padding: 0 10px;
  }

  .total-amount {
    padding-left: 10px;
  }
}
</style>
